import React, { useContext, useRef } from "react";
import { Chart } from "react-google-charts";
import DashboardContainer from "../../DashboardContainer";
import { useFetchNationalities } from "../../../../../Hooks/Dashboard";
import { ApplicationContext } from "../../../../../Context/DashbaordContext";
import { downloadCSV } from "../../DashboardFunctions/ExportApexFunction";

const GeoChart = () => {
  const chartRef = useRef(null);

  const { date, setDate, applicationStart } = useContext(ApplicationContext);

  const { data: nationalityData, isLoading } = useFetchNationalities({
    startDate: date.startDate,
    endDate: date.endDate,
    applicationStart: applicationStart,
  });

  const nationalities =
    nationalityData?.data?.map((item) => [item.label, item.value]) || [];

  const data = [["Country", "Total"], ...nationalities] || [
    ["Country", "Total"],
  ];

  const options = {
    colorAxis: {
      colors: [
        "#C5D6FF",
        "#A0BCFF",
        "#8CADFF",
        "#779EFF",
        "#5686FF",
        "#3B74FF",
        "#1A5CFF",
      ],
    },
    datalessRegionColor: "##F4F4F4",
    defaultColor: "#F4F4F4",
    legend: "none",
  };
  const isMobile = () => window.innerWidth <= 800;

  const handleDownload = () => {
    const categories = nationalityData?.data?.map((item) => item.label);
    const series = nationalityData?.data?.map((item) => item.value);
    const title = "Geo_Graph";
    downloadCSV(series, categories, title);
  };
  return (
    <DashboardContainer
      title='Nationality'
      noData={nationalities?.length === 0}
      handleExport={handleDownload}
      chartRef={chartRef}
      isLoading={isLoading}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        ref={chartRef}
      >
        <Chart
          chartType='GeoChart'
          height='400px'
          width={isMobile ? "90%" : "70%"}
          options={options}
          data={data}
        />
      </div>
    </DashboardContainer>
  );
};

export default GeoChart;
