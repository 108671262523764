import React from "react";
import styles from "./Buttons.module.css";

const Buttons = ({ text, to, handleOnClick, icon, width, rounded, color }) => {
  return (
    <button
      onClick={handleOnClick}
      className={styles[rounded ? "aud-button-rounded" : "aud-button"]}
      style={{
        width: width,
        borderRadius: rounded ? "25px" : "0px",
        backgroundColor: color ? color : "#1b224c",
      }}
    >
      {icon && <img src={icon} alt='Icon' className={styles["button-icon"]} />}
      {text}
    </button>
  );
};

export default Buttons;
