import React from "react";
import { Quill } from "react-quill";
import { useFetchNotificationOptions } from "../../../Hooks/Notification";
import QuillTollbarSelect from "./QuillTollbarSelect";
import "react-quill/dist/quill.snow.css"; // For snow theme
import ImageResize from "quill-image-resize-module-react";

// Registering size and font options
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

const Font = Quill.import("formats/font");
Font.whitelist = ["arial", "comic-sans", "courier-new", "georgia", "helvetica", "lucida"];
Quill.register(Font, true);

// Registering the image resize module
Quill.register("modules/imageResize", ImageResize);

function insertCustomTags(args) {
  const value = args;
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, value);
  this.quill.setSelection(cursorPosition + value.length);
}

export const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      insertCustomTags: insertCustomTags,
    },
  },
  imageResize: {
    modules: ["Resize", "DisplaySize"], 
    handleStyles: {
      backgroundColor: 'black',
      border: 'none',
  }
  },
};

export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "background",
  "color",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

// Quill Toolbar component
export const QuillToolbar = ({ setVariable, variable }) => {
  const { data: notificationOptions } = useFetchNotificationOptions();

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue) {
      setVariable(selectedValue);
    }
  };

  return (
    <div className='quill-container'>
      <div id='toolbar'>
        <span className='ql-formats'>
          <select className='ql-font'>
            <option value='arial' selected>Arial</option>
            <option value='comic-sans'>Comic Sans</option>
            <option value='courier-new'>Courier New</option>
            <option value='georgia'>Georgia</option>
            <option value='helvetica'>Helvetica</option>
            <option value='lucida'>Lucida</option>
          </select>
          <select className='ql-size' defaultValue='medium'>
            <option value='extra-small'>Small</option>
            <option value='small'>Normal</option>
            <option value='medium'>Medium</option>
            <option value='large'>Large</option>
            <option value='huge'>Huge</option>
          </select>
        </span>

        <span className='ql-formats'>
          <button className='ql-bold' />
          <button className='ql-italic' />
          <button className='ql-underline' />
          <button className='ql-strike' />
        </span>

        <span className='ql-formats'>
          <button className='ql-list' value='ordered' />
          <button className='ql-list' value='bullet' />
          <button className='ql-indent' value='-1' />
          <button className='ql-indent' value='+1' />
        </span>

        <span className='ql-formats'>
          <select className='ql-align' />
          <select className='ql-color' />
          <select className='ql-background' />
        </span>

        <span className='ql-formats'>
          <button className='ql-link' />
          <button className='ql-image' />
        </span>
      </div>

      {/* Add variable dropdown */}
      <select
        className='ql-insertCustomTags'
        defaultValue='Add Variable'
        onChange={handleSelectChange}
        value={variable}
        style={{ border: "none", fontWeight: "500" }}
      >
        <option value='Add Variable'>Add Variable</option>
        {notificationOptions?.data?.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
};
