import React from "react";
import Modal from "../../../Atoms/Modal/Modal";
import ModalChildrenContainer from "../../../Atoms/Modal/ModalChildrenContainer";
import { Form, Formik } from "formik";
import TextInput from "../../../Atoms/Inputs/TextInput";
import userIcon from "../../../../assets/Imagess/Contact/form/nameIcon.svg";
import * as Yup from "yup";
import EmailTextEditor from "../../Email/EmailTextEditor";
import Buttons from "../../../Atoms/Buttons/Buttons";
import { useAddESignature } from "../../../../Hooks/ESignature";
import LoaderButton from "../../Loader/LoaderButton";
import { toast } from "react-toastify";

const ESognatureModal = ({
  handleOnClose,
  type,
  mode,
  refetch,
  selectedItem,
  setSelectedItem,
}) => {
  const { mutate: addESignature, isLoading: isAddValues } = useAddESignature();
  const initialValues = {
    eSignatureName: selectedItem.signatureName || "",
    eSignature: selectedItem.signature || "",
  };
  const validationSchema = Yup.object().shape({
    eSignatureName: Yup.string().required("eSignatureName is required"),
    eSignature: Yup.string().required("eSignature is required"),
  });

  const handleSubmit = (values) => {
    addESignature(
      {
        emailSignatureId:
          selectedItem.id || "00000000-0000-0000-0000-000000000000",
        signatureName: values.eSignatureName,
        signature: values.eSignature,
      },
      {
        onSuccess: () => {
          handleOnClose();
          refetch();
          setSelectedItem({});
          toast.success(`${values.eSignatureName} is successfully added`);
        },
        onError: (error) => {
          toast.error(error.response?.data?.message);
        },
      }
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <Form>
            <Modal
              onClose={handleOnClose}
              title={
                mode === "add"
                  ? "New" + " " + "E-signature"
                  : "Edit" + " " + "E-signature"
              }
              width='50vw'
            >
              <ModalChildrenContainer>
                <TextInput
                  label='E-signature Name'
                  icon={userIcon}
                  placeholder='Enter Name'
                  name='eSignatureName'
                  value={values.eSignatureName}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  errors={errors.eSignatureName}
                  touched={touched.eSignatureName}
                />
                <EmailTextEditor
                  label='E-signature'
                  name='eSignature'
                  value={values.eSignature}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  errors={errors.eSignature}
                  touched={touched.eSignature}
                />
                {isAddValues ? (
                  <LoaderButton />
                ) : (
                  <Buttons
                    text={mode == "add" ? "Save" : "update"}
                    handleOnClick={handleSubmit}
                    type='submit'
                  />
                )}
              </ModalChildrenContainer>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ESognatureModal;
