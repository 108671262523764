import React from "react";
import WhiteContainer from "../../../HOC/WrappingContainer/WhiteContainer";
import Logs from "../../Custom/Logs/Logs";

const CampaignLogs = ({ campaignDetails }) => {
  const logs = [
    {
      applicantLogId: "1",
      logId: 12,
      logStatus: "Email reply",
      logDate: "2024-04-19T15:11:00",
    },
    {
      applicantLogId: "2",
      logId: 11,
      logStatus: "Status Update",
      logDate: "2024-04-19T15:11:00",
    },
  ];
  const campaignLogs = campaignDetails?.map((item) => {
    return {
      applicantLogId: item.campaignLogId,
      logId: item.logType,
      logStatus: item.logStatus,
      logDate: item.log.createdOn,
    };
  });
  return (
    <WhiteContainer white>
      <h1>History Logs</h1>
      <Logs logs={campaignLogs} />
    </WhiteContainer>
  );
};

export default CampaignLogs;
