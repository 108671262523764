import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styles from "./RightSideComp.module.css";
import TextInput from "../../../../../Atoms/Inputs/TextInput";
import folderIcon from "../../../../../../assets/Imagess/folderICon.svg";
import HorizantalLine from "../../../../../Atoms/Lines/HorizantalLine";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import ConditionalQueries from "./queryConditions/ConditionalQueries";

const ConditionsForm = forwardRef(({ selectedNode, view }, ref) => {
  const [init, setInit] = useState({});

  const validationSchema = Yup.object().shape({
    field: Yup.string().required("field is required"),
    operation: Yup.number().required("operation is required"),
    value: Yup.string().required("value is required"),
  });
console.log("selectedNode", selectedNode);
  useEffect(() => {
    const initialValues = {
      field: selectedNode?.data?.label.field || "",
      operation: selectedNode?.data?.label.operation,
      value: selectedNode?.data?.label.value,
    };
    setInit(initialValues);
  }, [selectedNode]);
  const formik = useFormik({
    initialValues: init,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
  console.log("cdvfdvdxfvd", values);

    },
  });

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      formik.submitForm();
    },
  }));

  useEffect(() => {
    ref.current = formik;
  }, [ref, formik]);

  console.log("cdsjbcjdsc", formik.values);
  return (
    <FormikProvider value={formik} innerRef={ref}>
      <div className={styles["email_container"]}>
        {/* <TextInput
          label='Condition 1'
          icon={folderIcon}
          placeholder='Yes'
          value={formik.values.conditionOne}
          required={true}
          name='conditionOne'
          onChange={(name, value) => {
            formik.setFieldValue(name, value);
          }}
          errors={formik.errors.conditionOne}
          touched={formik.touched.conditionOne}
          disabled
        />
        <TextInput
          label='Condition 2'
          icon={folderIcon}
          placeholder='No'
          name='conditionTwo'
          value={formik.values.conditionTwo}
          required={true}
          onChange={(name, value) => {
            formik.setFieldValue(name, value);
          }}
          errors={formik.errors.conditionTwo}
          touched={formik.touched.conditionTwo}
          disabled
        />
        <HorizantalLine /> */}
        <ConditionalQueries view={view} />
      </div>
    </FormikProvider>
  );
});

export default ConditionsForm;
