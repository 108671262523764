import { createContext, useContext, useState } from "react";

const ApplicantContext = createContext();

export const ApplicantProvider = ({ children }) => {
  const [isApplicant, setIsApplicant] = useState(true);

  return (
    <ApplicantContext.Provider value={{ isApplicant, setIsApplicant }}>
      {children}
    </ApplicantContext.Provider>
  );
};

export const useApplicantContext = () => {
  return useContext(ApplicantContext);
};
