import React, { useContext } from "react";
import DashboardContainer from "../../DashboardContainer";
import NationalityCard from "./NationalityCard";
import styles from "./NationalityCard.module.css";
import { useFetchNationalities } from "../../../../../Hooks/Dashboard";
import { ApplicationContext } from "../../../../../Context/DashbaordContext";
import { downloadCSV } from "../../DashboardFunctions/ExportApexFunction";

const Nationalities = () => {
  const { date, applicationStart } = useContext(ApplicationContext);
  const { data: nationalityData, isLoading } = useFetchNationalities({
    startDate: date.startDate,
    endDate: date.endDate,
    applicationStart: applicationStart,
  });

  const processedData =
    nationalityData?.data?.map((item) => ({
      name: item.label,
      number: item.value,
    })) || [];

  const handleDownload = () => {
    const title = "MostNationalitiesApplied_Graph";
    const series = nationalityData?.data?.map((item) => item.value);
    const categories = nationalityData?.data?.map((item) => item.label);
    downloadCSV(series, categories, title);
  };
  return (
    <DashboardContainer none>
      <DashboardContainer
        title='Most Nationality Applied'
        handleExport={handleDownload}
        isLoading={isLoading}
        noData={processedData?.length === 0}
      >
        <div className={styles["grid"]}>
          {processedData?.slice(0, 10)?.map((item) => {
            return (
              <div key={item.name}>
                <NationalityCard data={item} />
              </div>
            );
          })}
        </div>
      </DashboardContainer>
    </DashboardContainer>
  );
};

export default Nationalities;
