import React from "react";
import WhiteBoxContainer from "../../HOC/WrappingContainer/WhiteBoxContainer";
import styles from "../../Template/SingleApplicantTemplate/SingleApplicantPage.module.css";
import HorizantalLine from "../../Atoms/Lines/HorizantalLine";
import TitleImageComponent from "../Custom/Title_SubTitle/TitleImageComponent";
import StatusContainer from "../Custom/StatusContainer";
import CampaignSettings from "../Campaign/CampaignDetails/CampaignSettings";
import FlagComponent from "../../Atoms/CustomizedAtoms/FlagComponent";
const SingleCampaignHeader = ({ campaignDetails, refetch }) => {
  const dates = [
    {
      label: "Start Date",
      value: campaignDetails?.startDate?.substring(0, 10),
    },
    { label: "End Date", value: campaignDetails?.endDate?.substring(0, 10) },
    { label: "Term", value: campaignDetails?.termText },
  ];
  return (
    <WhiteBoxContainer>
      <div className={styles["subcontainer"]}>
        <div
          className={styles["title-subcontainer"]}
          style={{ color: "#1b224c" }}
        >
          <div className={styles["subTitle-img-cont"]}>
            <h3>{campaignDetails?.campaignName} </h3>{" "}
            <StatusContainer status={campaignDetails?.statusText} />
          </div>

          {campaignDetails?.isDeleted ? (
            <FlagComponent text='Deleted' />
          ) : (
            <CampaignSettings
              id={campaignDetails?.campaignId}
              title={campaignDetails?.campaignName}
              refetch={refetch}
              status={campaignDetails?.statusText}
            />
          )}
        </div>

        <HorizantalLine />
        <div className={styles["subTitle-cont"]}>
          {dates.map((date, index) => (
            <div key={index} className={styles["campaign_header_date"]}>
              <h3>{date.label}</h3>
              <h4>{date.value}</h4>
            </div>
          ))}
        </div>
      </div>
    </WhiteBoxContainer>
  );
};

export default SingleCampaignHeader;
