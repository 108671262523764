export const visibleColumnData = {
  firstName: true,
  lastName: true,
  middleName: false,
  applicationStart_Display: true,
  fieldOfInterest_Display: true,
  mobile: true,
  email: true,
  createdOn: true,
  statusText: true,
  createdOnDisplay: true,
  userAssigned: true,
  sourceOfConnection: false,
};
