import React from "react";
import SearchInput from "../../../Atoms/Inputs/SearchInput";
import Buttons from "../../../Atoms/Buttons/Buttons";
import ExportPrintComponent from "./ExportPrintComponent";
import plus from "../../../../assets/Imagess/plusvector.svg";
import searchIcon from "../../../../assets/Imagess/searchicon.svg";
import styles from "../../../Template/SettingsTemplate/SettingsPage.module.css";
const SettingsTopHeader = ({
  title,
  type,
  search,
  setSearch,
  handleAddClick = null,
  rightButton,
  handleExport,
  handlePrint,
  canAdd,
}) => {
  return (
    <>
      <div className={styles["icon-container"]}>
        <SearchInput
          icon={searchIcon}
          search={search}
          setSearch={setSearch}
          key={type}
        />
        <div className={styles["icon-subcontainer"]}>
          <div className={styles["button2"]}>
            {canAdd ? (
              <Buttons
                text={title}
                icon={plus}
                rounded={true}
                color='#F3223C'
                handleOnClick={handleAddClick}
              />
            ) : (
              ""
            )}
          </div>
          {rightButton}
          <ExportPrintComponent
            type={type}
            title={title}
            handleExport={handleExport}
            handlePrint={handlePrint}
          />
        </div>
      </div>

      <div className={styles["button"]}>
        <Buttons
          text={title}
          icon={plus}
          rounded={true}
          color='#F3223C'
          handleOnClick={handleAddClick}
        />
      </div>
    </>
  );
};

export default SettingsTopHeader;
