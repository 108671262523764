import { useEffect, useState, useRef, useCallback } from "react";
import WrappingContainer from "../../HOC/WrappingContainer/WrappingContainer";
import styles from "./ApplicantPage.module.css";
import applicanttabIcon from "../../../assets/Imagess/applicanticon.svg";
import intresetedtabIcon from "../../../assets/Imagess/interestedicon.svg";
import TableTopHeader from "../../Moecules/TableTopHeader/TableTopHeader";
import StatusModal from "../../Moecules/Settings/SettingsModal/StatusModal";
import EmailModal from "../../Moecules/Settings/SettingsModal/EmailModal";
import * as tableHeader from "../../Moecules/TableHeader/AplicantHeader";
import statusicon from "../../../assets/Imagess/status.svg";
import sendEmailICon from "../../../assets/Imagess/email.svg";
import {
  useExportApplicantsTable,
  useFetchPostApplicantData,
  useFetchPostInterestedData,
} from "../../../Hooks/Applicant";
import { useExportShowingInterestTable } from "../../../Hooks/Interested";
import { useNavigate } from "react-router-dom";
import TableComponent from "../../Moecules/TableComponent/TableComponent";
import ColoredButton from "../../Atoms/Buttons/ColoredButtons/ColoredButton";
import ColumnVisibility from "../../Moecules/TableTopHeader/ColumnVisibility";
import ApplicantGroupTab from "../../Atoms/Tabs/ApplicantGroupTab";
import SelectedNumberComponent from "../../Moecules/TableTopHeader/SelectedNumberComponent";
import automaticAssign from "../../../assets/Imagess/Users/UsersAssignment/automaticAssign.svg";
import AssignModal from "../../Moecules/UsersAssignment/AssignModal";
import TableFilter from "../../Moecules/Custom/TableFilter/TableFilter";
import PrintComponent from "../../Moecules/Custom/ExportComponent/PrintComponent";
import { useReactToPrint } from "react-to-print";
import { useSearchFilterContext } from "../../../Context/SearchFilterContext";
import { useApplicantContext } from "../../../Context/ApplicantContext";
import { visibleColumnData } from "./factory/VisibleColumnData";

const Child = ({ flag, setFlag }) => {
  const { filterState, updateFilterState, resetFilterState } =
    useSearchFilterContext();
  const {
    searchValue,
    orderByValue,
    orderDirValue,
    page,
    pageSize,
    filteredColumn,
    isFilter,
    filteredByField,
  } = filterState;
  const { isApplicant, setIsApplicant } = useApplicantContext();

  const navigate = useNavigate();
  const printRef = useRef();

  const [isAssignUser, setIsAssignUser] = useState({
    isAssigned: false,
    assignTitle: "Bulk Assign",
  });
  const [selectedApplicants, setSelectedApplicants] = useState([]);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [visibleColumn, setVisibleColumn] = useState(visibleColumnData);

  const {
    data: applicants,
    refetch: refetchApplicants,
    isLoading: isApplicantLoading,
  } = useFetchPostApplicantData({
    pageSize: pageSize,
    pageNumber: page,
    searchBy: searchValue,
    desc: orderDirValue == "desc" ? false : true,
    sortBy: orderByValue || "CreatedOn",
    selected: isApplicant,
    filterColumns: filteredColumn,
  });
  const {
    data: interested,
    refetch: refetchInterested,
    isLoading: isInterestedLoading,
  } = useFetchPostInterestedData({
    pageSize: pageSize,
    pageNumber: page,
    searchBy: searchValue,
    desc: orderDirValue == "desc" ? false : true,
    sortBy: orderByValue || "DateApplied",
    selected: !isApplicant,
    filterColumns: filteredColumn,
  });

  const exportShowingInterest = useExportShowingInterestTable();
  const exportApplicantTable = useExportApplicantsTable();

  const handleExport = async (exportFunction, fileName) => {
    try {
      const data = await exportFunction.mutateAsync();
      if (data.data instanceof Blob) {
        const blob = new Blob([data.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();

        window.URL.revokeObjectURL(link.href);
      } else {
        console.error("Unexpected data type. Expected string.");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleExportApplicants = () => {
    handleExport(exportApplicantTable, "ApplicantsTable");
  };

  const handleExportInterested = () => {
    handleExport(exportShowingInterest, "InterestedTable");
  };

  const handleNavigateToSinglePage = (e, id) => {
    e.preventDefault();
    navigate(`/home/applicants/${id}`, {
      state: {
        isApplicant: isApplicant ? true : false,
        query: {
          search: searchValue,
          orderBy: orderByValue,
          orderDir: orderDirValue,
        },
      },
    });
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    if (isApplicant) {
      refetchApplicants();
    } else {
      refetchInterested();
    }
  }, [
    isApplicant,
    orderByValue,
    orderDirValue,
    page,
    pageSize,
    filteredByField,
  ]);

  useEffect(() => {
    // if the flag is true we will reset the filters / it will be true after first render
    // this is to make sure when i change tabs it will reset but when i get back from another page it wont chang
    if (flag === true) {
      resetFilterState();
    } else {
      setFlag(true);
    }
    setSelectedApplicants([]);
    setVisibleColumn(visibleColumnData);
  }, [isApplicant]);

  const handleFilterUpdate = (field, value) => {
    updateFilterState(field, value);
  };

  //applicant tab
  const applicantTabContainer = [
    {
      text: "Prospects",
      icon: applicanttabIcon,
      number: applicants?.data?.totalCount,
      value: true,
    },
    {
      text: "Leads",
      icon: intresetedtabIcon,
      number: interested?.data?.totalCount,
      value: false,
    },
  ];

  // change status and email buttons when there is a selected applicants
  const additionalButtons = (
    <>
      <div className={`${styles["number"]}`}>
        {selectedApplicants?.length !== 0 && (
          <SelectedNumberComponent selected={selectedApplicants?.length} />
        )}
      </div>

      {selectedApplicants?.length !== 0 && (
        <>
          <ColoredButton
            title='Change Status'
            blue
            rounded={true}
            icon={statusicon}
            handleOnClick={() => setShowStatusModal(true)}
          />
          <ColoredButton
            title='Send Email'
            red
            rounded={true}
            icon={sendEmailICon}
            handleOnClick={() => setShowEmailModal(true)}
          />
          <ColoredButton
            title='Assign User'
            unfilled
            rounded={true}
            icon={automaticAssign}
            handleOnClick={() =>
              setIsAssignUser((prev) => ({
                ...prev,
                assignTitle: "Assign User",
                isAssigned: true,
              }))
            }
          />
        </>
      )}
    </>
  );

  const rightButtons = (
    <>
      <div>
        {isApplicant && applicants?.data?.totalCount > 999
          ? `${applicants?.data?.totalCount} prospect users`
          : !isApplicant && interested?.data?.totalCount > 999
          ? `${interested?.data?.totalCount} lead users`
          : null}
      </div>

      <ColumnVisibility
        tableHeader={
          isApplicant ? tableHeader.prospectHeader : tableHeader.leadTableHeader
        }
        visibleColumn={visibleColumn}
        setVisibleColumn={setVisibleColumn}
      />

      <TableFilter
        isFilter={isFilter}
        setIsFilter={(value) => handleFilterUpdate("isFilter", value)}
        setFilterByField={(value) =>
          handleFilterUpdate("filteredByField", value)
        }
        setFilteredColumn={(value) =>
          handleFilterUpdate("filteredColumn", value)
        }
      />
    </>
  );
  console.log("isFilter", isFilter);

  //table data that includes data refetch isloading and tableheader
  const tableData = isApplicant
    ? {
        data: {
          ...applicants?.data,
          items: applicants?.data?.items.map((item) => ({
            ...item,
            id: item.applicantId,
            foreignId: item.applicationId,
          })),
        },
        refetch: refetchApplicants,
        isLoading: isApplicantLoading,
        tableHeader: tableHeader.prospectHeader,
      }
    : {
        data: {
          ...interested?.data,
          items: interested?.data?.items.map((item) => ({
            ...item,
            id: item.studentId,
          })),
        },
        refetch: refetchInterested,
        isLoading: isInterestedLoading,
        tableHeader: tableHeader.leadTableHeader,
      };

  return (
    <>
      <WrappingContainer>
        <div>
          <div className={styles["tab-container"]}>
            <ApplicantGroupTab
              tabContainer={applicantTabContainer}
              selected={isApplicant}
              setSelected={setIsApplicant}
            />
          </div>
          <div className={styles["table-container"]}>
            <TableTopHeader
              isSearch
              search={searchValue}
              setSearch={(value) => handleFilterUpdate("searchValue", value)}
              handleExport={
                isApplicant ? handleExportApplicants : handleExportInterested
              }
              rightButtons={rightButtons}
              additionalButtons={additionalButtons}
              handlePrint={handlePrint}
            />
            <PrintComponent
              ref={printRef}
              title={isApplicant ? "Prospects Table" : "Leads Table"}
            >
              <TableComponent
                tableData={tableData}
                setSelected={setSelectedApplicants}
                selected={selectedApplicants}
                page={page}
                setPage={(value) => handleFilterUpdate("page", value)}
                pageSize={pageSize}
                setPageSize={(value) => handleFilterUpdate("pageSize", value)}
                setOrderBy={(value) =>
                  handleFilterUpdate("orderByValue", value)
                }
                setOrderDir={(value) =>
                  handleFilterUpdate("orderDirValue", value)
                }
                orderDir={orderDirValue}
                orderBy={orderByValue}
                setFilterByField={(value) =>
                  handleFilterUpdate("filteredByField", value)
                }
                filteredByField={filteredByField}
                visibleColumn={visibleColumn}
                handleRowClick={handleNavigateToSinglePage}
                checkBox={true}
                pagination={true}
                columnVisibility={true}
                isFilter={isFilter}
                isOrder
                setFilteredColumn={(value) =>
                  handleFilterUpdate("filteredColumn", value)
                }
              />
            </PrintComponent>
          </div>
        </div>
      </WrappingContainer>
      {showStatusModal && (
        <StatusModal
          handleOnClose={() => setShowStatusModal(false)}
          title='Change Status'
          isApplicant={isApplicant ? true : false}
          selected={selectedApplicants}
          setSelected={setSelectedApplicants}
          refetch={isApplicant ? refetchApplicants : refetchInterested}
        />
      )}
      {showEmailModal && (
        <EmailModal
          handleOnClose={() => setShowEmailModal(false)}
          title='Send Email'
          selectedApplicants={selectedApplicants}
          setSelectedApplicants={setSelectedApplicants}
          refetchData={isApplicant ? refetchApplicants : refetchInterested}
        />
      )}
      {isAssignUser.isAssigned && (
        <AssignModal
          handleOnClose={() =>
            setIsAssignUser((prev) => ({ ...prev, isAssigned: false }))
          }
          selected={selectedApplicants}
          setSelected={setSelectedApplicants}
          title={isAssignUser.assignTitle}
          refetch={isApplicant ? refetchApplicants : refetchInterested}
        />
      )}
    </>
  );
};

const AplicantsPage = () => {
  const [flag, setFlag] = useState(false);
  return <Child flag={flag} setFlag={setFlag} />;
};

export default AplicantsPage;
