import React, { useState } from "react";
import TextInput from "../../Atoms/Inputs/TextInput";
import Buttons from "../../Atoms/Buttons/Buttons";
import styles from "./LogInForm.module.css";
import { Formik, Form } from "formik";
import passicon from "../../../assets/Imagess/passwordloginicon.svg";
import emailicon from "../../../assets/Imagess/emailloginicon.svg";
import LoginValidationSchema from "../../../ValidationSchema/LoginValidationSchema";
import { useLogin } from "../../../Hooks/Authentication";
import { toast } from "react-toastify";
import LoaderButton from "../Loader/LoaderButton";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // to decode JWT token

const LogInForm = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const { mutate: login, isLoading: isLoginLoading } = useLogin();
  const initialValues = {
    username: "",
    password: "",
  };

  const handleLoginSubmit = (values) => {
    login(values, {
      onSuccess: (data) => {
        const token = data?.data?.token;
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.tokenExpiry < currentTime) {
          toast.error("Session expired. Please log in again.");
          return;
        }
        localStorage.setItem("token", token);
        localStorage.setItem("fullName", data?.data?.fullName);
        localStorage.setItem("settingPages", data?.data?.settingPages);
        localStorage.setItem(
          "sideBarPages",
          JSON.stringify(data?.data?.sideBarPages)
        );

        navigate("/home/applicants");
      },
      onError: (error) => {
        toast.error("Invalid Credentials");
        setError(true);
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={LoginValidationSchema}
      onSubmit={(values) => {
        handleLoginSubmit(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <Form>
            <div className={styles["form-container"]}>
              <TextInput
                icon={emailicon}
                width='100%'
                label='Email Address'
                placeholder='Enter your Email'
                required={true}
                name='username'
                value={values.username}
                onChange={(name, value) => {
                  setFieldValue(name, value);
                }}
                errors={errors.username}
                touched={touched.username}
              />
              <TextInput
                icon={passicon}
                width='100%'
                label='Password'
                placeholder='Enter your Password'
                type='password'
                required={true}
                name='password'
                value={values.password}
                onChange={(name, value) => {
                  var CryptoJS = require("crypto-js");
                  const secretKey = "AUD-CTS-109812jfgiubfg435345"; // Replace this with your secret key
                  const encryptedData = CryptoJS.AES.encrypt(
                    JSON.stringify(value),
                    secretKey
                  ).toString();

                  setFieldValue(name, encryptedData);
                }}
                errors={errors.password}
                touched={touched.password}
              />
              {isLoginLoading ? (
                <LoaderButton />
              ) : (
                <Buttons
                  text='Login'
                  width='100%'
                  handleOnClick={handleSubmit}
                  type='submit'
                />
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LogInForm;
