import React, { useState, useRef, useEffect } from "react";
import styles from "./DocumentUpload.module.css";
import AttachmentIcon from "../../../assets/Imagess/attachmentIconEmail.svg";
import ColoredButton from "../Buttons/ColoredButtons/ColoredButton";
import closeIcon from "../../../assets/Imagess/close Icon.svg";

const Attachments = ({ onChange, value, errors, touched }) => {
  const [files, setFiles] = useState(value || []);
  const fileInputRefs = useRef(null);

  useEffect(() => {
    setFiles(value || []);
  }, [value]);

  const handleFileInputClick = () => {
    // Reset the input value to allow uploading the same file after deletion
    fileInputRefs.current.value = null;
    fileInputRefs.current.click();
  };

  const uploadFile = (event) => {
    const selectedFiles = Array.from(event.target.files); // Convert FileList to an array
    if (selectedFiles.length === 0) return;

    const updatedFiles = [...files, ...selectedFiles]; // Concatenate new files to existing files
    setFiles(updatedFiles);
    onChange("Attachments", updatedFiles);

    fileInputRefs.current.value = null;
  };

  const handleRemove = (indexToRemove) => {
    const updatedFiles = files.filter((_, index) => index !== indexToRemove);
    setFiles(updatedFiles);
    onChange("Attachments", updatedFiles);
  };

  return (
    <div className={styles["attachment"]}>
      <label>Attachment</label>
      <div
        className={styles["attachment_container"]}
        style={{ border: errors && touched ? "1px solid #f3223c" : "" }}
      >
        <div className={styles["attachment_subContainer"]}>
          <img
            src={AttachmentIcon}
            alt='attachment'
            className={styles["attachment_icon"]}
          />
          {files.length > 0 ? (
            <div className={styles["attachment_fileContainer"]}>
              {files.map((file, index) => (
                <div key={index} className={styles["attachment_input"]}>
                  {(file.fileName ?? file.name) && (
                    <span>
                      {file.fileName?.substring(0, 7) ?? file.name?.substring(0, 7)}
                      ...
                    </span>
                  )}
                  <img
                    src={closeIcon}
                    alt='closeIcon'
                    onClick={() => handleRemove(index)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              ))}
            </div>
          ) : (
            <label>Add Attachment</label>
          )}

          <input
            type='file'
            name='file'
            placeholder='Choose Attachment'
            ref={fileInputRefs}
            onChange={uploadFile}
            multiple
            hidden
          />
        </div>

        <ColoredButton
          red
          title='Add More'
          rounded
          handleOnClick={handleFileInputClick}
        />
      </div>
    </div>
  );
};

export default Attachments;
