import React, { useCallback, useMemo } from "react";
import ReactFlow, {
  Background,
  Controls,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
} from "reactflow";
import "reactflow/dist/style.css";
import StartNode from "./StartNode";
import DiamondBox from "./DiamondBox";
import RectangularNode from "./RectangularNode";
import CustomEdge from "./CustomEdge";
import WhiteContainer from "../../../../../HOC/WrappingContainer/WhiteContainer";
import styles from "./Chart.module.css";
const Chart = ({
  nodes,
  setNodes,
  edges,
  setEdges,
  onNodeSelect,
  readOnly,
}) => {
  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );

  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  const handleAddNode = (type, action, sourceHandle, id, xPos, yPos) => {
    const newNodeId = (nodes.length + 1)?.toString();
    const newNode = {
      id: newNodeId,
      type,
      position: {
        x:
          sourceHandle === "left"
            ? xPos - 200
            : sourceHandle == "right"
            ? xPos + 200
            : action === "email"
            ? xPos - 50
            : xPos,
        y: sourceHandle ? yPos + 120 : yPos + 100,
      },
      data: {
        label: type.charAt(0).toUpperCase() + type.slice(1),
        action,
      },
    };

    setNodes((nds) => nds.concat(newNode));
    setEdges((eds) =>
      eds.concat({
        id: `e${id}-${newNodeId}`,
        source: id,
        target: newNodeId,
        sourceHandle,
      })
    );
  };

  const isLastSourceNode = (id, sourceHandle) => {
    return !edges?.some(
      (edge) => edge.source === id && edge.sourceHandle === sourceHandle
    );
  };

  const handleNodeClick = (e, node) => {

    onNodeSelect(node);
  };
  const nodeTypes = useMemo(
    () => ({
      startNode: (props) => (
        <StartNode
          {...props}
          isLastSourceNode={isLastSourceNode}
          onAddNode={handleAddNode}
          readOnly={readOnly}
        />
      ),
      rectangularNode: (props) => (
        <RectangularNode
          {...props}
          isLastSourceNode={isLastSourceNode}
          onAddNode={handleAddNode}  readOnly={readOnly}
        />
      ),
      diamondNode: (props) => (
        <DiamondBox
          {...props}
          isLastSourceNode={isLastSourceNode}
          onAddNode={handleAddNode}  readOnly={readOnly}
        />
      ),
    }),
    [edges]
  );

  const edgeTypes = useMemo(
    () => ({
      "custom-edge": CustomEdge,
    }),
    []
  );



  return (
    <WhiteContainer white height="auto">
      <div className={styles["chart_container"]}>
        <ReactFlow
          nodes={nodes}
          onNodesChange={!readOnly ? onNodesChange : null}
          edges={edges}
          onEdgesChange={!readOnly ? onEdgesChange : null}
          fitView={readOnly}
          edgeTypes={edgeTypes}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          onNodeClick={handleNodeClick}
        >
          <Controls />
          <Background />
        </ReactFlow>
      </div>
    </WhiteContainer>
  );
};

export default Chart;
