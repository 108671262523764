import React, { useContext, useRef } from "react";
import Chart from "react-apexcharts";
import DashboardContainer from "../../DashboardContainer";
import { ApplicationContext } from "../../../../../Context/DashbaordContext";
import { useFetchLevelOfEducation } from "../../../../../Hooks/Dashboard";
import styles from "./LevelOfEducation.module.css";
import { downloadCSV } from "../../DashboardFunctions/ExportApexFunction";

const LevelOfEducation = () => {
  const chartRef = useRef(null);
  const { date,  applicationStart } = useContext(ApplicationContext);

  const { data, isLoading } = useFetchLevelOfEducation({
    startDate: date.startDate,
    endDate: date.endDate,
    applicationStart: applicationStart,
  });

  const levelOfEducationData = data?.data || [];
  const series = levelOfEducationData?.map((i) => i.value) || [];
  const categories = levelOfEducationData?.map((i) => i.label) || [];

  const baseColors = ["#00A900", "#FFAE00", "#015699"];
  const colors = series.map(
    (_, index) => baseColors[index % baseColors.length]
  );

  const additionalWidth =
    categories.length > 5 ? (categories.length - 5) * 100 : 0;
  const calculatedWidth = 600 + additionalWidth;

  const options = {
    series: [
      {
        data: series,
      },
    ],
    chart: {
      type: "bar",
      height: 300,
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        barHeight: "90%",
        columnWidth: data?.data?.length == 1 ? "10%" : "30%",

        distributed: true,
        horizontal: false,
        dataLabels: {
          position: "top",
        },
      },
    },
    colors: colors,
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#fff"],
      },
      formatter: function (val) {
        return val ? val.toString() : "";
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#1b224c"],
      },
      dropShadow: {
        enabled: false,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: categories,
      labels: {
        show: true,
        style: {
          fontWeight: "600",
          colors: ["#1b224c"],
          fontSize: "12px",
        },
        formatter: function (val) {
          if (!val) return "";
          const values = val.split(" ");
          if (values.length > 1) {
            const array = values
              .map((name) => name.charAt(0).toUpperCase())
              .join(".");
            return array;
          } else {
            return values[0];
          }
        },
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const categoryName = w?.globals?.labels[dataPointIndex];
        return `
        <div style="padding: 10px; font-size: 12px;">
          <strong>${categoryName}</strong>
          
        </div>
      `;
      },
    },
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 1024,
        options: {
          chart: {
            height: 300,
          },
          plotOptions: {
            bar: {
              borderRadius: 8,
            },
          },
          dataLabels: {
            style: {
              fontSize: "10px",
            },
            offsetY: -15,
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "10px",
              },
            },
          },
        },
      },
      {
        breakpoint: 768,
        options: {
          chart: {
            height: 280,
          },
          plotOptions: {
            bar: {
              borderRadius: 6,
            },
          },
          dataLabels: {
            style: {
              fontSize: "9px",
            },
            offsetY: -12,
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "9px",
              },
            },
          },
        },
      },
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 250,
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
            },
          },
          dataLabels: {
            style: {
              fontSize: "8px",
            },
            offsetY: -10,
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "8px",
              },
            },
          },
        },
      },
    ],
  };

  const handleDownload = () => {
    const title = "LevelOfEducation_Graph";
    downloadCSV(series, categories, title);
  };

  return (
    <DashboardContainer
      title='Level of Education'
      noData={categories?.length === 0}
      hidden={options?.xaxis?.categories?.length > 3}
      handleExport={handleDownload}
      chartRef={chartRef}
      isLoading={isLoading}
    >
      <div
        className={`${styles["stacked_graph"]} ${
          options?.xaxis?.categories?.length > 3
            ? styles["stacked_graph_scrollable"]
            : ""
        }`}
        style={{ minWidth: calculatedWidth }}
        ref={chartRef}
      >
        <Chart
          options={options}
          series={options?.series}
          type='bar'
          height={380}
        />
      </div>
    </DashboardContainer>
  );
};

export default LevelOfEducation;
