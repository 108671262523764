import React, { useState, useEffect } from "react";
import styles from "./EmailContent.module.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomizedSelect from "../../Atoms/Inputs/CustomizedSelect";
import { QuillToolbar, modules, formats } from "./QuillToolBar";
const EmailTextEditor = ({
  name,
  value,
  onChange,
  errors,
  touched,
  label = "Email Content",
}) => {
  const [editorContent, setEditorContent] = useState(value || "");
  const [variable, setVariable] = useState("");

  const handleEditorChange = (content) => {
    setEditorContent(content);
    onChange(name, content);
    setVariable("");
  };

  useEffect(() => {
    setEditorContent((prev) => prev + variable);
  }, [variable]);


  return (
    <div className={styles["email-content"]}>
      <h4>
        {label} <span>{errors && touched ? errors : ""}</span>
      </h4>
    
      <div>
        <QuillToolbar setVariable={setVariable} variable={variable} />
        <ReactQuill
          theme='snow'
          value={editorContent}
          onChange={handleEditorChange}
          modules={modules}
          formats={formats}
        />
      </div>
    </div>
  );
};

export default EmailTextEditor;
