import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styles from "./RightSideComp.module.css";
import CustomizedSelect from "../../../../../Atoms/Inputs/CustomizedSelect";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import actionIcon from "../../../../../../assets/Imagess/action.svg";

const actionOptions = [{ value: 0, text: "Change Status" }];
const statusOptions = [
  { value: 0, text: "Interested" },
  { value: 1, text: "Not Interested" },
];

const ActionForm = forwardRef(({ selectedNode, view }, ref) => {
  const actionsRef = useRef();
  const [init, setInit] = useState({});

  const validationSchema = Yup.object().shape({
    actionChoice: Yup.string().required("Action is required"),
    changeTo: Yup.string().required("Change to is required"),
  });

  useEffect(() => {
    const initialValues = {
      actionChoice: selectedNode?.data?.label?.actionChoice ?? 0,
      changeTo: selectedNode?.data?.label?.changeTo ?? "",
    };

    setInit(initialValues);

   
  }, [selectedNode]);

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      actionsRef.current.submitForm();
    },
    get isValid() {
      return actionsRef.current.isValid;
    },
    get values() {
      return actionsRef.current.values;
    },
  }));

  useEffect(() => {
    ref.current = actionsRef.current;
  }, [ref]);

  return (
    <Formik
      initialValues={init}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
      }}
      innerRef={actionsRef}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <Form>
            <div className={styles["email_container"]}>
              <CustomizedSelect
                options={actionOptions}
                label='Actions'
                placeholder='Select Action'
                icon={actionIcon}
                name='actionChoice'
                value={
                  values.actionChoice === "" ? undefined : values.actionChoice
                }
                required={true}
                onChange={(name, value) => {
                  setFieldValue(name, value);
                }}
                errors={errors.actionChoice}
                touched={touched.actionChoice}
                disabled={view}
              />
              {values.actionChoice !== "" && (
                <CustomizedSelect
                  options={statusOptions}
                  status
                  label='Change To'
                  placeholder='Select Status'
                  icon={actionIcon}
                  name='changeTo'
                  value={values.changeTo === "" ? undefined : values.changeTo}
                  required={true}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  errors={errors.changeTo}
                  disabled={view}
                  touched={touched.changeTo}
                />
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
});

export default ActionForm;
