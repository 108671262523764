import React from "react";
import styles from "./SinglaTab.module.css";

const SingleTab = ({
  active,
  text,
  handleOnClick,
  icon,
  number,
  fontSize,
  blue = false,
  rounded = false,
}) => {
  const tabClass = active ? "active-tab" : "inactive-tab";
  const roundedTabClass = active
    ? "rounded-active-tab"
    : "rounded-inactive-tab";
  const tabImage = active ? "activeTabImage" : "";
  return (
    <div
      className={`${
        !rounded ? styles["rounded-single-tab"] : styles["single-tab"]
      } ${!rounded ? styles[tabClass] : styles[roundedTabClass]}`}
      onClick={handleOnClick}
    >
      {icon && (
        <img
          src={icon}
          alt='icon'
          className={`${styles[tabImage]} ${
            blue && !active ? styles["blueImage"] : ""
          }`}
        />
      )}
      <h3
        className={fontSize ? styles["text-style"] : ""}
        style={{ color: blue && !active ? "#007FD9" : "" }}
      >
        {text}
      </h3>
    </div>
  );
};

export default SingleTab;
