import React, { useState, useEffect } from "react";
import styles from "./RolesDropDown.module.css";
import dashboardInactive from "../../../assets/Imagess/dashboardInactive.svg";
import dashboardactive from "../../../assets/Imagess/dashboardactive.svg";
import dropdownicon from "../../../assets/Imagess/dropdownicon.svg";
import Toggle from "../../Atoms/Inputs/Toggle";
import upArrow from "../../../assets/Imagess/uparrow.svg";
import privilegeicon from "../../../assets/Imagess/privilegeicon.svg";
import CheckBox from "../../Atoms/Inputs/CheckBox";
import { useFetchPageActions } from "../../../Hooks/Roles";
import EmptyText from "../Custom/EmptyText";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";

const RolesDropDown = ({ roleDetails, setRoleDetails, isAddingRole }) => {
  const [openDiv, setOpenDiv] = useState("");

  const { data: actionPages, isLoading } = useFetchPageActions();

  const handlePageCheckboxChange = (pageId) => {
    console.log("cdsmcksdm", pageId);
    const existingPage = roleDetails?.find((item) => item.pageId === pageId);

    if (!existingPage) {
      setRoleDetails([...roleDetails, { pageId, pageActionId: "" }]);
    } else {
      const updatedRoleDetails = roleDetails?.filter(
        (item) => item.pageId !== pageId
      );
      setRoleDetails(updatedRoleDetails);
    }
  };

  const handleActionToggle = (pageId, pageActionId) => {
    const existingEntryIndex = roleDetails?.findIndex(
      (item) => item.pageId === pageId && item.pageActionId === pageActionId
    );

    let updatedRoleDetails;

    if (existingEntryIndex === -1) {
      updatedRoleDetails = [...roleDetails, { pageId, pageActionId }];
    } else {
      updatedRoleDetails = roleDetails?.filter(
        (item, index) => index !== existingEntryIndex
      );
    }

    setRoleDetails(updatedRoleDetails);
  };

  const isPageSelected = (pageId) => {
    return roleDetails?.some((item) => item.pageId === pageId);
  };

  const isActionSelected = (pageId, pageActionId) => {
    return roleDetails?.some(
      (item) => item.pageId === pageId && item.pageActionId === pageActionId
    );
  };

  const handleOnClick = (value) => {
    // if (!isPageSelected(value.pageName)) {
    //   toast.warning("Please select the page before viewing its actions.");
    //   return;
    // }
    setOpenDiv((prevOpenDiv) => (prevOpenDiv === value ? "" : value));
  };

  if (isAddingRole || isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.Rolescontainer}>
      <div className={styles.subTitleContainer}>
        <img src={privilegeicon} alt='privillages' />
        <h4>Privilege Actions</h4>
      </div>

      {actionPages?.data?.pageActions?.map((page) => (
        <div key={page.pageId}>
          <div
            className={styles.container}
            onClick={() => handleOnClick(page.pageName)}
          >
            <div className={styles.subTitleContainer}>
              <CheckBox
                onChange={() => (
                  handlePageCheckboxChange(page.pageId),
                  handleOnClick(page.pageName)
                )}
                value={isPageSelected(page.pageId)}
              />
              {/* <img
                src={
                  openDiv === page.pageName
                    ? dashboardactive
                    : dashboardInactive
                }
                alt='dropdown'
              /> */}
              <h4
                className={
                  openDiv === page.pageName
                    ? styles.activeTitle
                    : styles.inactiveTitle
                }
              >
                {page.name}
              </h4>
            </div>
            {/* <img
              src={
                openDiv === page.pageName &&
                roleDetails?.find((item) => item.pageId === page.pageId)
                  ? upArrow
                  : dropdownicon
              }
              alt='dropdown'
            /> */}
          </div>

          {openDiv === page.pageName &&
            roleDetails?.find((item) => item.pageId === page.pageId) && (
              <div
                className={`${styles.openBox} ${
                  openDiv === page.pageName ? styles.active : ""
                }`}
              >
                <div className={styles.subopenBox}>
                  {page?.childActions.length > 0 ? (
                    page?.childActions?.map((action) => (
                      <Toggle
                        text={action.actionName}
                        key={action.pageActionId}
                        onChange={() =>
                          handleActionToggle(page.pageId, action.pageActionId)
                        }
                        isActive={isActionSelected(
                          page.pageId,
                          action.pageActionId
                        )}
                      />
                    ))
                  ) : (
                    <EmptyText text='Actions' />
                  )}
                </div>
              </div>
            )}
        </div>
      ))}
    </div>
  );
};

export default RolesDropDown;
