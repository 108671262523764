import React from "react";
import WhiteBoxContainer from "../../../../HOC/WrappingContainer/WhiteBoxContainer";
import TitlePageHeader from "../../../Settings/SettingsMolecules/TitlePageHeader";
import runIcon from "../../../../../assets/Imagess/Users/UsersAssignment/runIcon.svg";
import Stepper from "../Stepper/Stepper";
import styles from "./CampaignHeader.module.css";
import { useParams } from "react-router-dom";
const Header = ({ steps, activeStep, handleNavigation }) => {
  const { id } = useParams();
  return (
    <WhiteBoxContainer>
      <div className={styles["mobile_header"]}>
        {" "}
        <TitlePageHeader
          title={id ? "Create New Campaign" : "Update Campaign"}
          buttonText={activeStep == 2 ? "Save" : "Next"}
          handleOnClick={handleNavigation}
          icon={runIcon}
          // secondButton={secondButton}
        />
      </div>

      <Stepper steps={steps} activeStep={activeStep} />
    </WhiteBoxContainer>
  );
};

export default Header;
