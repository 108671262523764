import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Chart from "./Chart/Chart";
import WhiteContainer from "../../../../HOC/WrappingContainer/WhiteContainer";
import styles from "./CreateEmailFlow.module.css";
import EmailForm from "./RightSideComponents/EmailForm";
import ActionForm from "./RightSideComponents/ActionForm";
import ConditionsForm from "./RightSideComponents/ConditionsForm";
import WaitingForm from "./RightSideComponents/WaitingForm";
import Buttons from "../../../../Atoms/Buttons/Buttons";
import { toast } from "react-toastify";
import { useAddCampaignstage3 } from "../../../../../Hooks/Campaign";
import { useNavigate, useParams } from "react-router-dom";
import CreateEmailWorkFlowMobile from "./EmailWorkFlowMobile/CreateEmailWorkFlowMobile";
import Loader from "../../../Loader/Loader";

const CreateEmailWorkFlow = forwardRef(
  ({ campaignDetails, isLoadingdata }, ref) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const formRef = useRef();

    const [selectedNode, setSelectedNode] = useState({});
    const [showMobilePopup, setShowMobilePopup] = useState(false);
    const [contentToShow, setContentToShow] = useState(null);
    const [edges, setEdges] = useState([]);
    const [nodes, setNodes] = useState([
      {
        id: "1",
        type: "startNode",
        position: { x: 250, y: 5 },
        data: { label: "Start" },
      },
    ]);
    const { mutate: addStage3, isLoading: isAddingStage3 } =
      useAddCampaignstage3();

    useEffect(() => {
      const fetchedEdges =
        campaignDetails?.campaignEdges?.length > 0
          ? campaignDetails?.campaignEdges?.map((item) => {
              return {
                id: item.edgeName,
                source: item.sourceId?.toString(),
                sourceHandle: item.sourceHandle || null,
                target: item.targetId?.toString(),
              };
            })
          : [];
      setEdges(fetchedEdges);

      const fetchedNodes =
        campaignDetails?.campaignNodes?.length > 0
          ? campaignDetails?.campaignNodes?.map((item) => {
              if (item.diagramType == 0) {
                return {
                  nodeId: item.nodeId || "00000000-0000-0000-0000-000000000000",
                  type: "rectangularNode",
                  data: {
                    label: {
                      email:
                        item.emailTemplate &&
                        item.emailTemplate[0]?.emailTemplateId,
                      emailCategory:
                        item.emailTemplate && item.emailTemplate[0]?.emailTag,
                    },
                    action: "email",
                  },
                  position: { x: item.positionX, y: item.positionY },
                  id: item.intId?.toString(),
                  innerId:
                    item.innerId || "00000000-0000-0000-0000-000000000000",
                };
              } else if (item.diagramType == 1) {
                return {
                  nodeId: item.nodeId || "00000000-0000-0000-0000-000000000000",
                  type: "rectangularNode",
                  data: {
                    label: {
                      wait: item.waitTime,
                    },
                    action: "wait",
                  },
                  position: { x: item.positionX, y: item.positionY },
                  id: item.intId?.toString(),
                  innerId:
                    item.innerId || "00000000-0000-0000-0000-000000000000",
                };
              } else if (item.diagramType == 3) {
                return {
                  nodeId: item.nodeId || "00000000-0000-0000-0000-000000000000",
                  type: "rectangularNode",
                  data: {
                    label: {
                      actionChoice:
                        item.actions && item.actions[0]?.actionChoice,
                      changeTo:
                        item.actions && parseInt(item.actions[0]?.updateTo),
                    },
                    action: "action",
                  },
                  position: { x: item.positionX, y: item.positionY },
                  id: item.intId?.toString(),
                  innerId:
                    item.innerId || "00000000-0000-0000-0000-000000000000",
                };
              } else if (item.diagramType == 2) {
                return {
                  nodeId: item.nodeId || "00000000-0000-0000-0000-000000000000",
                  type: "diamondNode",
                  data: {
                    label: {
                      field:
                        item.conditions && item.conditions[0]?.audienceOptionId,
                      operation: item.conditions && item.conditions[0]?.inNotIn,
                      value: item.conditions && item.conditions[0]?.valueString,
                    },
                    action: "condition",
                  },
                  position: { x: item.positionX, y: item.positionY },
                  id: item.intId?.toString(),
                  innerId:
                    item.innerId || "00000000-0000-0000-0000-000000000000",
                };
              } else {
                return {
                  nodeId: item.nodeId || "00000000-0000-0000-0000-000000000000",
                  type: "startNode",
                  data: { label: "Start" },
                  position: { x: item.positionX, y: item.positionY },
                  id: item.intId?.toString(),
                  innerId:
                    item.innerId || "00000000-0000-0000-0000-000000000000",
                };
              }
            })
          : [
              {
                id: "1",
                type: "startNode",
                position: { x: 250, y: 5 },
                data: { label: "Start" },
              },
            ];
      setNodes(fetchedNodes);
    }, [campaignDetails]);

    const isMobile = () => window.innerWidth <= 800;

    const dataType = [
      {
        type: "email",
        content: <EmailForm ref={formRef} selectedNode={selectedNode} />,
      },
      {
        type: "wait",
        content: <WaitingForm ref={formRef} selectedNode={selectedNode} />,
      },
      {
        type: "action",
        content: <ActionForm ref={formRef} selectedNode={selectedNode} />,
      },
      {
        type: "condition",
        content: <ConditionsForm ref={formRef} selectedNode={selectedNode} />,
      },
    ];

    useEffect(() => {
      if (selectedNode && selectedNode.data && selectedNode.data.action) {
        setContentToShow(
          dataType.find((item) => item.type === selectedNode.data.action)
            ?.content
        );
      } else {
        setContentToShow(null);
      }
    }, [selectedNode]);
    useEffect(() => {
      setContentToShow(null);
    }, [nodes]);
    // Inside handleNodeSelect
    const handleNodeSelect = (node) => {
      setSelectedNode(node);
      if (isMobile()) {
        setShowMobilePopup(true);
      }
    };

    const handleChangeNodeLabel = async () => {
      try {
        await formRef.current.submitForm();
        const formIsValid = formRef.current?.isValid;

        if (formIsValid) {
          const updatedNodes = nodes.map((node) =>
            node.id === selectedNode.id
              ? {
                  ...node,
                  data: { ...node.data, label: formRef.current?.values },
                }
              : node
          );
          setNodes(updatedNodes);
          setShowMobilePopup(false);
          setSelectedNode({});
          // formRef.current?.resetForm();
        } else {
          toast.error("Fill all fields");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    };
    const handleAddStep3 = () => {
      const nodeToSend = nodes
        ?.map((item) => {
          if (item.data.action == "email") {
            if (!item.data.label.email || !item.data.label.emailCategory) {
              toast.error("Email and Email Category are required.");
              return null;
            }
            return {
              nodeId: item.nodeId || "00000000-0000-0000-0000-000000000000",
              type: "email",
              positionX: item.position.x,
              positionY: item.position.y,
              intId: parseInt(item.id),
              innerId: item.innerId || "00000000-0000-0000-0000-000000000000",
              emailTemplateId: item.data.label.email,
              emailTag: item.data.label.emailCategory,
            };
          } else if (item.data.action == "wait") {
            if (!item.data.label.wait) {
              toast.error("Wait time is required.");
              return null;
            }
            return {
              nodeId: item.nodeId || "00000000-0000-0000-0000-000000000000",
              type: "wait",
              positionX: item.position.x,
              positionY: item.position.y,
              intId: parseInt(item.id),
              innerId: item.innerId || "00000000-0000-0000-0000-000000000000",
              waitTime: item.data.label.wait.toString(),
            };
          } else if (item.data.action == "condition") {
            if (
              !item.data.label.field ||
              item.data.label.operation == undefined ||
              item.data.label.value == null ||
              item.data.label.value == undefined
            ) {
              toast.error("Field, Operation, and Value are required.");
              return null;
            }
            return {
              nodeId: item.nodeId || "00000000-0000-0000-0000-000000000000",
              type: "condition",
              positionX: item.position.x,
              positionY: item.position.y,
              intId: parseInt(item.id),
              innerId: item.innerId || "00000000-0000-0000-0000-000000000000",
              conditionFilter: {
                andOr: true,
                audienceOptionId: item.data.label.field,
                inNotIn:
                  item?.data?.label.operation === 0
                    ? true
                    : item?.data?.label.operation === 1
                    ? false
                    : item?.data?.label.operation,
                valueIds: item.data.label.value.toString(),
              },
            };
          } else if (item.data.action == "action") {
            if (
              item.data.label.actionChoice == null ||
              item.data.label.changeTo == null
            ) {
              toast.error("Action Choice and Change To are required.");
              return null;
            }
            return {
              nodeId: item.nodeId || "00000000-0000-0000-0000-000000000000",
              type: "action",
              positionX: item.position.x,
              positionY: item.position.y,
              intId: parseInt(item.id),
              innerId: item.innerId || "00000000-0000-0000-0000-000000000000",
              actionChoice: item.data.label.actionChoice,
              changeTo: item.data.label.changeTo,
            };
          } else {
            return {
              nodeId: item.nodeId || "00000000-0000-0000-0000-000000000000",
              type: "start",
              positionX: item.position.x,
              positionY: item.position.y,
              intId: parseInt(item.id),
              innerId: item.innerId || "00000000-0000-0000-0000-000000000000",
            };
          }
        })
        .filter((item) => item !== null);

      if (nodeToSend.length !== nodes.length) {
        return;
      }

      const edgesToSend = edges.map((item) => {
        const edge = {
          edgeName: item.id,
          sourceId: parseInt(item.source),
          targetId: parseInt(item.target),
        };

        if (item.sourceHandle !== null) {
          edge.sourceHandle = item.sourceHandle;
        }

        return edge;
      });

      addStage3(
        {
          nodez: nodeToSend,
          edgez: edgesToSend,
          campaignId: localStorage.getItem("campaignId"),
        },
        {
          onSuccess: () => {
            if (id) {
              toast.success("Campaign is updated successfully");
            } else {
              toast.success("Campaign is created successfully");
            }
            setTimeout(() => {
              navigate("/home/campaigns");
            }, [800]);
          },
        }
      );
    };

    useImperativeHandle(ref, () => ({
      handleAddStep3,
    }));

    if (isLoadingdata || isAddingStage3) {
      return <Loader />;
    }
    return (
      <div className={styles.emailflowContainer}>
        <Chart
          nodes={nodes}
          setNodes={setNodes}
          edges={edges}
          setEdges={setEdges}
          onNodeSelect={handleNodeSelect}
        />

        <div className={styles.emailDetailsContainer}>
          <WhiteContainer white height='auto'>
            {contentToShow || (
              <p>Please click on a node to select the conditions</p>
            )}
            {contentToShow && (
              <Buttons text='Confirm' handleOnClick={handleChangeNodeLabel} />
            )}
          </WhiteContainer>
        </div>
        <div className={styles.emailWokFlowMobile}>
          <CreateEmailWorkFlowMobile
            start={selectedNode?.data?.label === "Start"}
            showMobilePopup={showMobilePopup}
            handleOnClose={() => setShowMobilePopup(false)}
            content={
              dataType.find((item) => item.type === selectedNode?.data?.action)
                ?.content
            }
            button={
              selectedNode?.data?.action && (
                <Buttons text='Confirm' handleOnClick={handleChangeNodeLabel} />
              )
            }
          />
        </div>
      </div>
    );
  }
);
export default CreateEmailWorkFlow;
