import React, { useState } from "react";
import Modal from "../../Atoms/Modal/Modal";
import ModalChildrenContainer from "../../Atoms/Modal/ModalChildrenContainer";
import TextInput from "../../Atoms/Inputs/TextInput";
import Buttons from "../../Atoms/Buttons/Buttons";
import usericon from "../../../assets/Imagess/userinputicon.svg";
import emailicon from "../../../assets/Imagess/emailinputicon.svg";
import roleicon from "../../../assets/Imagess/roleinputicon.svg";
import mobileICon from "../../../assets/Imagess/mobileInputIcon.png";
import CustomizedSelect from "../../Atoms/Inputs/CustomizedSelect";
import { Formik, Form } from "formik";
import { useAddUser, useEditUser } from "../../../Hooks/Users";
import { toast } from "react-toastify";
import LoaderButton from "../Loader/LoaderButton";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import PhoneNumber from "../../Atoms/Inputs/PhoneNumber";
import CheckBox from "../../Atoms/Inputs/CheckBox";
import TextArea from "../../Atoms/Inputs/TextArea";
import EmailTextEditor from "../Email/EmailTextEditor";
import styles from "./UserModal.module.css";
import { useFetchRoles } from "../../../Hooks/Roles";
const UserModal = ({
  handleOnClose,
  title,
  label1,
  label2,
  isOpen,
  mode,
  refetchUsers,
  selectedUser,
  setSelectedUser,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const {
    data: roles,
    isLoading: isRolesLoading,
    refetch: refetchRoles,
  } = useFetchRoles({ selected: true });
  const { mutate: addUser, isLoading: isAddingUser } = useAddUser();
  const { mutate: editUser, isLoading: isEdittingUser } = useEditUser();

  const initialValues = {
    password: "",
    email: selectedUser?.email || "",
    firstNameEn: selectedUser?.firstNameEn || "",
    lastNameEn: selectedUser?.lastNameEn || "",
    roleId: selectedUser?.roleId || "",
    signature: selectedUser?.signature || "",
    isRequirement: selectedUser?.isRequirement || false,
    mobile: selectedUser?.mobile || "",
    username: selectedUser?.userName || "",
  };

  const getValidationSchema = (mode) => {
    let baseSchema = {
      password: Yup.string(),
      username: Yup.string().required("Username is required"),
      email: Yup.string()
        .required("Email is required")
        .email("Invalid email format"),
      firstNameEn: Yup.string().required("firstNameEn is required"),
      lastNameEn: Yup.string().required("lastNameEn is required"),
      isRequirement: Yup.boolean(),
      roleId: Yup.string().required("role is required"),
      mobile: Yup.string().required("mobile is required"),
      signature: Yup.mixed().when("isRequirement", {
        is: true,
        then: (schema) => schema.required("signature is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
    };
    if (mode === "add") {
      baseSchema.password = baseSchema.password.required(
        "Password is required"
      );
    } else {
      baseSchema.password = baseSchema.password.notRequired();
    }

    return Yup.object().shape(baseSchema);
  };

  const addUSerValidationSchema = Yup.object().shape({
    password: Yup.string().when("mode", {
      is: (mode) => mode === "add",
      then: (schema) => schema.required("Password is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    username: Yup.string().required("Username is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email format"),
    firstNameEn: Yup.string().required("firstNameEn is required"),
    lastNameEn: Yup.string().required("lastNameEn is required"),
    isRequirement: Yup.boolean(),
    roleId: Yup.string().required("role is required"),
    mobile: Yup.string().required("mobile is required"),
    signature: Yup.mixed().when("isRequirement", {
      is: true,
      then: (schema) => schema.required("signature is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });
  const handleSave = (values) => {
    const valuesTosend = { ...values };
    addUser(valuesTosend, {
      onSuccess: () => {
        refetchUsers();
        handleOnClose();
        toast.success("User is added");
      },
      onError: (error, data) => {
        console.error("An error occurred:", error?.response?.data?.message);
        toast.error("Email or username already exists");
        setErrorMessage(error?.response?.data?.message);
      },
    });
  };

  const handleEdit = (values) => {
    const includedValues = {
      userId: selectedUser?.userId,
    };
    const valuesTosend = { ...values, ...includedValues };
    editUser(valuesTosend, {
      onSuccess: () => {
        refetchUsers();
        handleOnClose();
        toast.success("User is editted");
      },
      onError: (error, data) => {
        console.error("An error occurred:", error?.response?.data?.message);
        toast.error("Email or username already exists");
        setErrorMessage(error?.response?.data?.message);
      },
    });
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getValidationSchema(mode)}
      onSubmit={(values) => {
        if (mode === "add") {
          handleSave(values);
        } else {
          handleEdit(values);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <Form>
            <Modal
              onClose={handleOnClose}
              title={
                mode === "add" ? "Add" + " " + title : "Edit" + " " + title
              }
              width='60vw'
            >
              <ModalChildrenContainer>
                <div className={styles["useModal_container"]}>
                  <TextInput
                    label='First Name'
                    icon={usericon}
                    placeholder='Enter First Name'
                    name='firstNameEn'
                    value={values.firstNameEn}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    errors={errors.firstNameEn}
                    touched={touched.firstNameEn}
                  />{" "}
                  <TextInput
                    label='Last Name'
                    icon={usericon}
                    placeholder='Enter Last Name'
                    name='lastNameEn'
                    value={values.lastNameEn}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    errors={errors.lastNameEn}
                    touched={touched.lastNameEn}
                  />
                  <TextInput
                    label='Username'
                    icon={usericon}
                    placeholder='Enter username'
                    name='username'
                    value={values.username}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    errors={errors.username || errorMessage}
                    touched={touched.username || errorMessage}
                  />
                  <TextInput
                    label='Email'
                    icon={emailicon}
                    placeholder='Enter Email'
                    name='email'
                    value={values.email}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    errors={errors.email || errorMessage}
                    touched={touched.email || errorMessage}
                  />
                  {mode == "add" ? (
                    <TextInput
                      label='Password'
                      icon={emailicon}
                      placeholder='Enter Password'
                      type='password'
                      name='password'
                      value={values.password}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      errors={errors.password}
                      touched={touched.password}
                    />
                  ) : (
                    ""
                  )}
                  <PhoneNumber
                    icon={mobileICon}
                    label='Mobile'
                    name='mobile'
                    value={values.mobile}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    errors={errors.mobile}
                    touched={touched.mobile}
                    placeholder='Enter Mobile'
                  />
                  <CustomizedSelect
                    options={roles?.data?.map(({ roleId, roleName }) => ({
                      value: roleId,
                      text: roleName,
                    }))}
                    icon={roleicon}
                    label='Role'
                    placeholder='Enter Role'
                    name='roleId'
                    value={values.roleId}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    errors={errors.roleId}
                    touched={touched.roleId}
                  />
                </div>
                <CheckBox
                  text='Recruitment Team'
                  name='isRequirement'
                  value={values.isRequirement}
                  onChange={(checked) => {
                    setFieldValue("isRequirement", checked);
                  }}
                  errors={errors.isRequirement}
                  touched={touched.isRequirement}
                />
                {values.isRequirement === true && (
                  <EmailTextEditor
                    label='E-signature'
                    name='signature'
                    value={values.signature}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    errors={errors.signature}
                    touched={touched.signature}
                  />
                )}
                <div>
                  {isAddingUser || isEdittingUser ? (
                    <LoaderButton />
                  ) : (
                    <>
                      {mode === "add" ? (
                        <Buttons
                          text='Save'
                          handleOnClick={handleSubmit}
                          width='100%'
                        />
                      ) : (
                        <Buttons
                          text='Update'
                          handleOnClick={handleSubmit}
                          width='100%'
                        />
                      )}
                    </>
                  )}
                </div>
              </ModalChildrenContainer>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserModal;
