import React, { useContext, useRef } from "react";
import Chart from "react-apexcharts";
import DashboardContainer from "../../DashboardContainer";
import { useFetchAgeGraphData } from "../../../../../Hooks/Dashboard";
import styles from "./AgeGraph.module.css";
import { ApplicationContext } from "../../../../../Context/DashbaordContext";
import { downloadCSV } from "../../DashboardFunctions/ExportApexFunction";

const AgeGraph = () => {
  const chartRef = useRef(null);

  const { date, applicationStart } = useContext(ApplicationContext);
  const { data, isLoading } = useFetchAgeGraphData({
    startDate: date.startDate,
    endDate: date.endDate,
    applicationStart: applicationStart,
  });

  const ageData = data?.data || [];
  const series = ageData?.map((item) => item.value) || [];
  const labels = ageData?.map((item) => item.label) || [];

  const isMobile = () => window.innerWidth <= 800;
  const additionalWidth = ageData.length > 3 ? (ageData.length - 5) * 100 : 0;
  const calculatedWidth = 400 + additionalWidth;

  const options = {
    series: [{ data: series }],
    chart: {
      type: "bar",
      height: 300,
      toolbar: {
        show: true,
        tools: {
          download: true,
        },
        export: {
          csv: {
            filename: "age-data",
            columnDelimiter: ",",
            headerCategory: "Category",
            headerValue: "Value",
          },
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        distributed: true,
        columnWidth: ageData?.length === 1 ? "10%" : "40%",
        horizontal: false,
        dataLabels: {
          position: "top",
        },
      },
    },
    colors: ["#2463EB"],
    dataLabels: {
      position: "top",
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#fff"],
      },
      formatter: function (val) {
        return val !== null && val !== undefined ? val.toString() : "";
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#1b224c"],
      },
      dropShadow: {
        enabled: false,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: labels,
      labels: {
        show: true,
        style: {
          fontWeight: "600",
          colors: ["#1b224c"],
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const categoryName = w?.globals?.labels[dataPointIndex];
        return `
        <div style="padding: 10px; font-size: 12px;">
          <strong> Age: ${categoryName}</strong>
          
        </div>
      `;
      },
    },
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 1024,
        options: {
          chart: {
            height: 300,
          },
          plotOptions: {
            bar: {
              borderRadius: 8,
            },
          },
          dataLabels: {
            style: {
              fontSize: "10px",
            },
            offsetY: -15,
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "10px",
              },
            },
          },
        },
      },
      {
        breakpoint: 768,
        options: {
          chart: {
            height: 280,
          },
          plotOptions: {
            bar: {
              borderRadius: 6,
            },
          },
          dataLabels: {
            style: {
              fontSize: "9px",
            },
            offsetY: -12,
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "9px",
              },
            },
          },
        },
      },
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 250,
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              width: "10%",
            },
          },
          dataLabels: {
            style: {
              fontSize: "8px",
            },
            offsetY: -10,
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "8px",
              },
            },
          },
        },
      },
    ],
  };

  const handleDownload = () => {
    const categories = labels;
    const title = "Age_Graph";
    downloadCSV(series, categories, title);
  };

  return (
    <DashboardContainer
      title='Age'
      hidden={data?.data?.length > 3}
      noData={ageData?.length === 0}
      handleExport={handleDownload}
      chartRef={chartRef}
      isLoading={isLoading}
    >
      <div
        className={`${styles["stacked_graph"]} ${
          options?.xaxis?.categories?.length > 3
            ? styles["stacked_graph_scrollable"]
            : ""
        }`}
        style={{ minWidth: calculatedWidth }}
        ref={chartRef}
      >
        <div>
          <Chart
            options={options}
            series={options?.series}
            type='bar'
            height={380}
          />
        </div>
      </div>
    </DashboardContainer>
  );
};

export default AgeGraph;
